import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { vacancyUpgradeSuccess } from 'src/components/Notifications/EmployerVacancies';
import { upgradeManagerVacancy } from 'src/models/employerVacancies/managerVacancies';
import { clearUpgradeInfo } from 'src/models/employerVacancies/vacancyUpgrades';
import fetcher from 'src/utils/fetcher';

export default (params, addNotification) => async (dispatch) => {
    let vacancy;
    try {
        vacancy = (await fetcher.get('/shards/employer/vacancies/get_full_vacancy', { params })).vacancies[0];
    } catch (error) {
        defaultRequestErrorHandler(error, addNotification);
    }
    dispatch([clearUpgradeInfo(params.vacancyId), upgradeManagerVacancy({ vacancy })]);

    addNotification(vacancyUpgradeSuccess, {
        props: {
            name: vacancy.name,
            vacancyProperties: vacancy.vacancyProperties,
        },
    });
};
