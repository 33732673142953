import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import vacancyUpgradeButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/vacancy/vacancy_upgrade_button_click';
import { vacancyUpgradeElementShownRaw } from '@hh.ru/analytics-js-events/build/xhh/employer/vacancy/vacancy_upgrade_element_shown';
import { Link, usePush } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { formatToReactComponent } from 'bloko/common/trl';
import urlParser from 'bloko/common/urlParser';

import { createCartAutoActionUpgrade } from 'src/api/price/cart';
import upgradeVacancy from 'src/api/vacancyUpgrades/upgradeVacancy';
import FormatMoney from 'src/components/FormatMoney';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { currencyType, productCodeType, regionIdType } from 'src/utils/price/propTypes';

const FINISH_URL = '/employer/invoice/finish';
const CARTS_URL = '/employer/carts';

const VARIANT_BUY = 'BUY';
const VARIANT_UPGRADE = 'UPGRADE';
const VARIANT_ACTIVATE = 'ACTIVATE';

const getCartUrl = (carts, vacancyId) => {
    if (carts?.length === 1) {
        const finishUrl = urlParser(FINISH_URL);
        finishUrl.params.cartId = carts[0].cartId;
        finishUrl.params.backurl = `/vacancy/${vacancyId}#upgrade`;
        return finishUrl.href;
    }
    return CARTS_URL;
};

const Features = {
    upgradePopupHideActivateButton: 'upgrade_popup_hide_activate_button',
};

const UpgradeVariants = ({
    afterVacancyTypeHasBeenChanged,
    isEdit,
    suitableUpgrades,
    upgradeVacancy,
    backurl,
    vacancyId,
    employerId,
    handleClose,
    trls,
}) => {
    const hideActivateButton = useSelector(({ features }) => features[Features.upgradePopupHideActivateButton]);
    const { addNotification } = useNotification();
    const push = usePush();

    useEffect(() => {
        suitableUpgrades?.forEach((suitableUpgrade) => {
            let currentUpgrade = VARIANT_BUY;
            if (suitableUpgrade.canUpgrade) {
                currentUpgrade = VARIANT_UPGRADE;
            }
            if (suitableUpgrade.canActivate) {
                currentUpgrade = VARIANT_ACTIVATE;
            }
            if (suitableUpgrade.quotasExhausted) {
                return;
            }
            vacancyUpgradeElementShownRaw({
                type: suitableUpgrade.metallic,
                vacancyId,
                currentUpgrade,
            });
        });
    }, [suitableUpgrades, vacancyId]);

    if (!suitableUpgrades) {
        return null;
    }

    const renderActivateButton = (carts, vacancyId, sendAnalytics) => (
        <Button
            kind={ButtonKind.Primary}
            Element={Link}
            to={getCartUrl(carts, vacancyId)}
            data-qa="vacancy-upgrade-popup-submit_activate"
            onClick={() => sendAnalytics(VARIANT_ACTIVATE)}
        >
            {trls[UpgradeVariants.trls.activate]}
        </Button>
    );

    const renderUpgradeButton = (metallic, sendAnalytics) => (
        <Button
            kind={ButtonKind.Primary}
            onClick={() => {
                sendAnalytics(VARIANT_UPGRADE);
                upgradeVacancy(
                    {
                        afterVacancyTypeHasBeenChanged,
                        isEdit: !!isEdit,
                        params: { vacancyId, metallic },
                    },
                    addNotification,
                    push
                )
                    .then(handleClose)
                    .catch(console.error);
            }}
            data-qa="vacancy-upgrade-popup-submit_upgrade"
        >
            {trls[UpgradeVariants.trls.update]}
        </Button>
    );

    const renderBuyButton = (
        { purchase, metallic, quotasExhausted, canUpgrade, canBuy, canActivate },
        sendAnalytics
    ) => {
        const onBuyButtonClickAutoActionUpgrade = () => {
            sendAnalytics(VARIANT_BUY);
            createCartAutoActionUpgrade(
                purchase.products,
                metallic,
                addNotification,
                push,
                {
                    backurl: backurl.buy,
                    source: 'upgradePopup',
                    hhtmFromLabel: 'vacancy_upgrade_popup',
                },
                vacancyId,
                employerId ? String(employerId) : undefined
            );
        };

        Analytics.sendHHEvent('element_shown', {
            elementName: 'auto_action_upgrade_vacancy_button_element_shown',
            vacancyId: `${vacancyId}`,
            totalPrice: purchase.totalPrice,
            upgradeType: metallic,
            quotasExhausted,
            canUpgrade,
            canBuy,
            canActivate,
        });

        return (
            <Button
                kind={ButtonKind.Primary}
                onClick={onBuyButtonClickAutoActionUpgrade}
                data-qa="vacancy-upgrade-popup-submit_buy"
            >
                {formatToReactComponent(trls[UpgradeVariants.trls.buyFor], {
                    '{0}': (
                        <FormatMoney inCents={true} currency={purchase.currency}>
                            {purchase.totalPrice}
                        </FormatMoney>
                    ),
                })}
            </Button>
        );
    };

    const renderButton = (suitableUpgrade) => {
        const sendAnalytics = (currentUpgrade) => {
            vacancyUpgradeButtonClick({
                type: suitableUpgrade.metallic,
                vacancyId,
                currentUpgrade,
            });
        };

        if (suitableUpgrade.canUpgrade) {
            return renderUpgradeButton(suitableUpgrade.metallic, sendAnalytics);
        }
        if (suitableUpgrade.canActivate && !hideActivateButton) {
            return renderActivateButton(suitableUpgrade.carts, vacancyId, sendAnalytics);
        }
        if (suitableUpgrade.quotasExhausted) {
            return (
                <span data-qa="vacancy-upgrade-popup_quotas-exhausted">
                    {trls[UpgradeVariants.trls.quotasExhausted]}
                </span>
            );
        }
        if (suitableUpgrade.purchase) {
            return renderBuyButton(suitableUpgrade, sendAnalytics);
        }

        return null;
    };

    return (
        <>
            {suitableUpgrades.map((suitableUpgrade) => (
                <div key={suitableUpgrade.metallic} data-qa={`vacancy-upgrade-variant_${suitableUpgrade.metallic}`}>
                    <VSpacing base={5} />
                    <Text strong>{trls[UpgradeVariants.trls[`${suitableUpgrade.metallic}_IMPROVE`]]}</Text>
                    <div className="vacancy-upgrade-variant-info">
                        <div className="vacancy-upgrade-variant-benefits">
                            {trls[UpgradeVariants.trls[suitableUpgrade.metallic]]}
                        </div>
                        <div className="vacancy-upgrade-variant-button">{renderButton(suitableUpgrade)}</div>
                    </div>
                </div>
            ))}
        </>
    );
};

UpgradeVariants.propTypes = {
    afterVacancyTypeHasBeenChanged: PropTypes.func,
    trls: PropTypes.object,
    isEdit: PropTypes.bool,
    suitableUpgrades: PropTypes.arrayOf(
        PropTypes.shape({
            // STANDARD | STANDARD_PLUS | PREMIUM
            metallic: PropTypes.string.isRequired,
            canUpgrade: PropTypes.bool.isRequired,
            canActivate: PropTypes.bool.isRequired,
            canBuy: PropTypes.bool.isRequired,
            quotasExhausted: PropTypes.bool.isRequired,
            carts: PropTypes.arrayOf(
                PropTypes.shape({
                    cartId: PropTypes.number,
                })
            ),
            // Может отсутствовать, если нет вариантов покупки
            purchase: PropTypes.shape({
                // В копейках
                totalPrice: PropTypes.number.isRequired,
                currency: PropTypes.string,
                // VPPL с (VP | RENEWAL_VP | VPREM) внутри
                products: PropTypes.arrayOf(
                    PropTypes.shape({
                        code: productCodeType,
                        count: PropTypes.number,
                        currency: currencyType,
                        // Должен быть periodType, но бэк отдаёт так
                        period: PropTypes.string,
                        price: PropTypes.number,
                        pricePerOne: PropTypes.number,
                        products: PropTypes.shape({
                            code: productCodeType,
                            count: PropTypes.number,
                        }),
                        profRoleGroup: PropTypes.arrayOf(PropTypes.string),
                        region: regionIdType,
                    })
                ),
            }),
        })
    ),
    backurl: PropTypes.object,
    vacancyId: PropTypes.number,
    employerId: PropTypes.number,
    upgradeVacancy: PropTypes.func,
    handleClose: PropTypes.func,
};

UpgradeVariants.trls = {
    quotasExhausted: 'vacancy.tools.improve.quotasExhausted',
    STANDARD_PLUS_IMPROVE: 'vacancy.tools.improve.standard_plus',
    PREMIUM_IMPROVE: 'vacancy.tools.improve.premium',
    STANDARD_IMPROVE: 'vacancy.tools.improve.standard',
    PREMIUM: 'vacancy.tools.improve.premium.benefits',
    STANDARD_PLUS: 'vacancy.tools.improve.standard_plus.benefits',
    STANDARD: 'vacancy.tools.improve.standard.benefits',
    buyFor: 'vacancy.tools.improve.buyFor',
    update: 'vacancy.update.yes',
    activate: 'vacancy.tools.improve.toCarts',
};

export const UpgradeVariantsRaw = translation(UpgradeVariants);

export default connect(
    ({ vacancyUpgrades }, { vacancyId }) => ({
        suitableUpgrades: vacancyUpgrades[vacancyId]?.suitableUpgrades,
        backurl: vacancyUpgrades[vacancyId]?.backurl,
    }),
    { upgradeVacancy }
)(UpgradeVariantsRaw);
