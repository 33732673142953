import { FC, PropsWithChildren } from 'react';

import FormVSpacing, { FormVSpacingType } from 'src/components/VacancyCreate/common/FormVSpacing';

const FormItem: FC<PropsWithChildren> = ({ children, ...props }) => {
    if (children) {
        return (
            <div {...props}>
                <FormVSpacing value={FormVSpacingType.FieldItem} />
                {children}
            </div>
        );
    }
    return <FormVSpacing value={FormVSpacingType.FieldItem} />;
};

export default FormItem;
