import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import fetcher from 'src/utils/fetcher';

import getUpgradedEditBody from 'src/api/vacancyUpgrades/getUpgradedEditBody';
import getUpgradedFullVacancy from 'src/api/vacancyUpgrades/getUpgradedFullVacancy';

export default ({ afterVacancyTypeHasBeenChanged, isEdit, params }, addNotification, push) =>
    async (dispatch, getState) => {
        const { location } = getState().router;
        let response;
        try {
            response = await fetcher.postFormData('/shards/employer/vacancies/upgrade', params);
            afterVacancyTypeHasBeenChanged?.();
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
        }
        if (location.pathname.match('/vacancy/')) {
            push(`${location.pathname}${response.data.query}`);
            return;
        }

        if (isEdit) {
            dispatch(getUpgradedEditBody(params, addNotification));
        } else {
            dispatch(getUpgradedFullVacancy(params, addNotification));
        }
    };
