import { useSelector } from 'src/hooks/useSelector';
import VACANCY_ACTIONS from 'src/utils/constants/vacancyActions';

interface UseIsEditHook {
    (): {
        isEdit: boolean;
        isEditPendingDraft: boolean;
        isEditVacancyOrPendingDraft: boolean;
    };
}

const useIsEdit: UseIsEditHook = () => {
    const vacancyAction = useSelector((state) => state.vacancyAction);

    return {
        isEdit: vacancyAction === VACANCY_ACTIONS.EDIT,
        isEditPendingDraft: vacancyAction === VACANCY_ACTIONS.EDIT_PENDING_DRAFT,
        isEditVacancyOrPendingDraft:
            vacancyAction === VACANCY_ACTIONS.EDIT || vacancyAction === VACANCY_ACTIONS.EDIT_PENDING_DRAFT,
    };
};

export default useIsEdit;
