import { LangTrls } from 'bloko/common/hooks/useTranslations';

import routePaths from 'src/app/routePaths';
import { ProlongateUnavailableReason, ProlongateInfo } from 'src/models/prolongateInfo';

// Не перемещать в /utils, т.к. там не работает экстрактор переводов
const TrlKeys = {
    errorNonProlongableVacanciesOne: 'vacancy.rise.error.nonProlongableVacancies.one',
    errorNonProlongableVacanciesMany: 'vacancy.rise.error.nonProlongableVacancies.many',

    [ProlongateUnavailableReason.AccessDenied]: 'hhapi.vacancy.prolongation.error.ACCESS_DENIED',
    [ProlongateUnavailableReason.Archived]: 'hhapi.vacancy.prolongation.error.ARCHIVED',
    [ProlongateUnavailableReason.AreaVacancyPostDisabled]:
        'hhapi.vacancy.prolongation.error.AREA_VACANCY_POST_DISABLED',
    [ProlongateUnavailableReason.FreeNotMinimumProlongationPeriod]:
        'hhapi.vacancy.prolongation.error.FREE_NOT_MINIMUM_PROLONGATION_PERIOD',
    [ProlongateUnavailableReason.FreeProlongationNotAvailable]:
        'hhapi.vacancy.prolongation.error.FREE_PROLONGATION_NOT_AVAILABLE',
    [ProlongateUnavailableReason.FreeRenewalsAndPaidProlongationsMixed]:
        'hhapi.vacancy.prolongation.error.FREE_RENEWALS_AND_PAID_PROLONGATIONS_MIXED',
    [ProlongateUnavailableReason.NotPremoderated]: 'hhapi.vacancy.prolongation.error.NOT_PREMODERATED',
    [ProlongateUnavailableReason.PayForPerformanceProlongationDenied]:
        'hhapi.vacancy.prolongation.error.PAY_FOR_PERFORMANCE_NOT_MINIMUM_DAYS_TO_PROLONGATION',
    [ProlongateUnavailableReason.StandardNotMinimumProlongationPeriod]:
        'hhapi.vacancy.prolongation.error.STANDARD_NOT_MINIMUM_PROLONGATION_PERIOD',
    [ProlongateUnavailableReason.StandardPlusNotMinimumDaysToProlongation]:
        'hhapi.vacancy.prolongation.error.STANDARD_PLUS_NOT_MINIMUM_DAYS_TO_PROLONGATION',
    [ProlongateUnavailableReason.Unknown]: 'hhapi.vacancy.prolongation.error.UNKNOWN',
};

export const getProlongationError = (
    trls: LangTrls,
    reason?: ProlongateUnavailableReason
): {
    isKnownError: boolean;
    errorText: string;
} => {
    if (
        !reason ||
        !Object.values(ProlongateUnavailableReason).includes(reason) ||
        reason === ProlongateUnavailableReason.Unknown
    ) {
        return {
            isKnownError: false,
            errorText: trls[TrlKeys[ProlongateUnavailableReason.Unknown]],
        };
    }

    return {
        isKnownError: true,
        errorText: trls[TrlKeys[reason]],
    };
};

export const validateResponse = (
    trls: LangTrls,
    unavailable: ProlongateInfo['unavailable'],
    vacancyIds: number[]
): string | null => {
    // Если не все вакансии попали в unavailable, значит с какой-то из выбранных что-то сделать можно
    if (unavailable.vacancyIds.length !== vacancyIds.length) {
        return null;
    }

    const reasons = unavailable.reasons as Record<number, ProlongateUnavailableReason>;

    // Если причин почему нельзя поднять вакансии несколько, то пишем общую ошибку "Не получится поднять вакансии"
    if (Object.keys(reasons).length > 1) {
        return trls[TrlKeys.errorNonProlongableVacanciesMany];
    }

    const vacancyId = unavailable.vacancyIds[0];
    const reason = reasons[vacancyId];

    const { isKnownError, errorText } = getProlongationError(trls, reason);

    return isKnownError ? errorText : trls[TrlKeys.errorNonProlongableVacanciesOne];
};

type Type = 'regularly' | 'classificationChanges';

export const openBlankRisePage = (ids: number[], type?: Type, targetBlank = true, fromPage?: string): void => {
    const params = new URLSearchParams();
    ids.forEach((id) => params.append('id', `${id}`));
    if (type) {
        params.append('type', type);
    }
    if (fromPage) {
        params.append('fromPage', fromPage);
    }
    window.open(`${routePaths.employerVacanciesRise}?${params.toString()}`, targetBlank ? '_blank' : '_self');
};
