// tempexp_34940_file

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import addHours from 'date-fns/addHours';
import compareAsc from 'date-fns/compareAsc';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import useExperiment, { useExperimentControl } from 'src/hooks/useExperiment';
import { useIsHeadHunterPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import { CountryId } from 'src/models/countryId';
import fetcher from 'src/utils/fetcher';

const VACANCY_ADVICE_PLACE_A = 'exp_34940_vacancy_advice_place_A';
const VACANCY_ADVICE_PLACE_B = 'exp_34940_vacancy_advice_place_B';
const VACANCY_ADVICE_PLACE_C = 'exp_34940_vacancy_advice_place_C';

const IGNORE_ADVICE_RISE_INFO_URL = '/shards/employer/vacancy/ignore_advice';

const setVacancyAdvicePlaceAllowedAction = makeSetStoreField('isVacancyAdvicePlaceAllowed');
const setVacancyAdviceOpenedFromSnackAction = makeSetStoreField('isVacancyAdviceOpenedFromSnack');

declare global {
    interface FetcherPostApi {
        [IGNORE_ADVICE_RISE_INFO_URL]: {
            queryParams: {
                vacancyId: number;
            };
            body: null;
            response: null;
        };
    }
}

interface UseRiseStandardSale {
    isExperiment: boolean;
    isTestGroupPlaceA: boolean;
    isTestGroupPlaceB: boolean;
    isTestGroupPlaceC: boolean;
    isVacancyAdvicePlaceAllowedToday: boolean;
    setNotAllowedVacancyAdvicePlaceForToday: () => void;
    resetTrigger: () => void;
    triggerOnAdviceFromSnack: () => void;
    triggerOffAdviceFromSnack: () => void;
}
export function useVacancyAdvice(isEditMode?: boolean): UseRiseStandardSale {
    const dispatch = useDispatch();
    const vacancyId = useSelector(({ vacancyView }) => vacancyView.vacancyId);
    const isRussianEmployer = useSelector(
        ({ employerCountryIdBySeller }) => employerCountryIdBySeller?.toString() === CountryId.Russia
    );
    // будет в true если совет не показывали еще за последнии 24 часа
    const isVacancyAdvicePlaceAllowedToday = useSelector(
        ({ isVacancyAdvicePlaceAllowed }) => isVacancyAdvicePlaceAllowed
    );
    // можно ли вакансию поднимать
    const isRiseAllowed = useSelector((state) => !!state.availableVacancyActions.update);

    // если поднимали  вакансию за последнии 24 часа то будет false
    const isMoreThan24HoursHasPassedSinceLastRise = useSelector(({ vacancyView }) =>
        vacancyView.systemInfo?.publicationTime
            ? compareAsc(new Date(), addHours(new Date(vacancyView.systemInfo.publicationTime), 24)) === 1
            : false
    );
    // если создали вакансию менее чем 2 дня назад то будет  false
    const isMoreThan48HoursHasPassedSinceCreated = useSelector(({ vacancyView }) =>
        vacancyView.systemInfo?.creationTime
            ? compareAsc(new Date(), addHours(new Date(vacancyView.systemInfo.creationTime), 48)) === 1
            : false
    );

    const isHHPlatform = useIsHeadHunterPlatform();
    const isHHRu = useIsHhru();
    const shouldCheckExperiment = isEditMode
        ? isHHPlatform && isHHRu && isRussianEmployer
        : isHHPlatform &&
          isHHRu &&
          isRussianEmployer &&
          isVacancyAdvicePlaceAllowedToday &&
          isRiseAllowed &&
          isMoreThan24HoursHasPassedSinceLastRise &&
          isMoreThan48HoursHasPassedSinceCreated;

    const setNotAllowedVacancyAdvicePlaceForToday = useCallback(() => {
        if (vacancyId) {
            return fetcher.post(IGNORE_ADVICE_RISE_INFO_URL, null, { params: { vacancyId } }).then(() => {
                dispatch(setVacancyAdvicePlaceAllowedAction(false));
            });
        }
        return null;
    }, [vacancyId, dispatch]);

    const isTestGroupPlaceA = useExperiment(VACANCY_ADVICE_PLACE_A, shouldCheckExperiment, !isEditMode);
    const isTestGroupPlaceB = useExperiment(VACANCY_ADVICE_PLACE_B, shouldCheckExperiment, !isEditMode);
    const isTestGroupPlaceC = useExperiment(VACANCY_ADVICE_PLACE_C, shouldCheckExperiment, !isEditMode);

    const isControlGroup = useExperimentControl(
        [VACANCY_ADVICE_PLACE_A, VACANCY_ADVICE_PLACE_B, VACANCY_ADVICE_PLACE_C],
        shouldCheckExperiment,
        false
    );

    const isTestGroup = isTestGroupPlaceA || isTestGroupPlaceB || isTestGroupPlaceC;

    const isExperiment = isTestGroup || isControlGroup;

    const resetTrigger = useCallback(() => {
        const currentSearchParams = new URLSearchParams(location.search);
        currentSearchParams.delete('advice');
        history.replaceState(null, '', `?${String(currentSearchParams)}${location.hash}`);
    }, []);

    const triggerOnAdviceFromSnack = useCallback(() => {
        dispatch(setVacancyAdviceOpenedFromSnackAction(true));
    }, [dispatch]);
    const triggerOffAdviceFromSnack = useCallback(() => {
        dispatch(setVacancyAdviceOpenedFromSnackAction(false));
    }, [dispatch]);

    return {
        isExperiment,
        isTestGroupPlaceA,
        isTestGroupPlaceB,
        isTestGroupPlaceC,
        isVacancyAdvicePlaceAllowedToday,
        setNotAllowedVacancyAdvicePlaceForToday,
        resetTrigger,
        triggerOnAdviceFromSnack,
        triggerOffAdviceFromSnack,
    };
}
