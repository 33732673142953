// tempexp_32484_file
import useExperiment from 'src/hooks/useExperiment';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import { CountryId } from 'src/models/countryId';

export const EXPERIMENT_NAME_CLASSIFICATION_CHANGES = 'exp_32484_classification_changes';

export enum FromPages {
    MyVacancies = 'from-my-vacancies',
    Vacancy = 'from-vacancy',
    Statportal = 'from-statportal',
    VacancyEdit = 'from-vacancy-edit',
}

interface UseClassificationChangesPage {
    isExpClassificationChangesPage: boolean;
}

export const useClassificationChangesPage = (sendCheck = false): UseClassificationChangesPage => {
    const isRussianEmployer = useSelector(
        ({ employerCountryIdBySeller }) => employerCountryIdBySeller?.toString() === CountryId.Russia
    );
    const isZp = useIsZarplataPlatform();
    const isHHRu = useIsHhru();
    const shouldCheckExperiment = !isZp && isHHRu && isRussianEmployer;
    const isExpClassificationChangesPage = useExperiment(
        EXPERIMENT_NAME_CLASSIFICATION_CHANGES,
        shouldCheckExperiment,
        sendCheck
    );

    return {
        isExpClassificationChangesPage,
    };
};
