import { FC, PropsWithChildren } from 'react';

export const DATA_NAME = 'data-hh-anchor-name';
export const DATA_PATTERN = 'create-vacancy-form-anchor_{0}';

const FormScrollAnchor: FC<
    {
        name: string;
    } & PropsWithChildren
> = ({ name }) => {
    return (
        <input
            type="checkbox"
            name={name}
            tabIndex={-1}
            className="create-vacancy-form-anchor"
            {...{ [DATA_NAME]: DATA_PATTERN.replace('{0}', name) }}
        />
    );
};

export default FormScrollAnchor;
