import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { vacancyUpdateInitialBodyAction } from 'src/models/vacancyCreate/vacancyCreateInitialBody';
import fetcher from 'src/utils/fetcher';

const vacancyFeaturesAction = makeSetStoreField('vacancyFeatures');

export default (params, addNotification) => async (dispatch, getState) => {
    const { employerManagerId } = getState().vacancyCreateDefaultBody;
    let upgradedInfo;
    try {
        upgradedInfo = await fetcher.get('/shards/employer/vacancies/upgraded_vacancy_info', {
            params: { ...params, employerManagerId },
        });
    } catch (err) {
        defaultRequestErrorHandler(err, addNotification);
        return;
    }

    dispatch([
        vacancyFeaturesAction(upgradedInfo.vacancyFeatures),
        vacancyUpdateInitialBodyAction(upgradedInfo.vacancyCreateInitialBody),
    ]);
};
